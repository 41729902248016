import './ViewTemplateSection.scss';
import React, { useRef, useEffect } from 'react';

// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faBold, faItalic } from "@fortawesome/pro-solid-svg-icons";
import { faNote } from "@fortawesome/sharp-light-svg-icons";

interface ChangeNotesProps {
    text: string;
    setText: (value: string) => void;
}

export const ChangeNotes: React.FC<ChangeNotesProps> = ({ text, setText }) => {
    //const [isBoldActive, setIsBoldActive] = useState<boolean>(false);
    //const [isItalicActive, setIsItalicActive] = useState<boolean>(false);
    const contentEditableRef = useRef<HTMLDivElement>(null);
    const maxChars = 300;

    // Update the content of the editable div only when the text prop changes
    useEffect(() => {
        if (contentEditableRef.current && contentEditableRef.current.innerText !== text) {
            contentEditableRef.current.innerText = text;
        }
    }, [text]);

    const handleInput = () => {
        if (contentEditableRef.current) {
            let textContent = contentEditableRef.current.innerText;

            // Remove excessive consecutive new lines (e.g., limit to 2 consecutive new lines)
            textContent = textContent.replace(/\n{3,}/g, '\n\n');

            // Check if the text content exceeds maxChars
            if (textContent.length > maxChars) {
                textContent = textContent.substring(0, maxChars);
            }

            setText(textContent);

            // Restore the trimmed content back to the div
            contentEditableRef.current.innerText = textContent;

            // Move the caret to the end of the text
            const range = document.createRange();
            const sel = window.getSelection();
            range.selectNodeContents(contentEditableRef.current);
            range.collapse(false);
            if (sel) {
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    };

    return (
        <div>
            <div className='notesHeader'>
                <h2>
                    <FontAwesomeIcon id="uploadTemplateIcon" icon={faNote} />
                    &nbsp;
                    Change Notes
                </h2>
            </div>
            <div>&nbsp;</div>
            <div className='notes'>
                <div
                    ref={contentEditableRef}
                    contentEditable
                    className="changeNotes"
                    onInput={handleInput}
                    style={{
                        border: '2px solid #ccc',
                        padding: '10px',
                        minHeight: '12vh',
                        minWidth: '38vh',
                        maxHeight: '12vh',
                        maxWidth: '38vh',
                        fontSize: '14px',
                        overflow: 'auto',  
                        whiteSpace: 'pre-wrap'
                    }}
                ></div>
            </div>
        </div>
    );
};

export default ChangeNotes;
