import './Header.scss';
import { NavLink } from "react-router-dom";
import DotLogo from 'components/DotLogo';
import NavBar from 'components/NavBar/NavBar';


type Props = {
    pageName: string;
    userName?: string;
    supplierName?: string;
    vendorNumber?: string;
}
 
export default function Header({pageName, userName, supplierName, vendorNumber}: Props) {
    return(
        <header className='headerStyle'> 
            <div className='loginBanner'> 
                    <div className='userName'>
                        <p><strong>Welcome:</strong> {userName}</p>
                    </div>
                    &nbsp;
                    &nbsp;
                    <div className='divider'></div>
                    &nbsp;
                    &nbsp;
                    <div className='supplierName'>
                        <p>{supplierName}</p>
                    </div>
                    &nbsp;
                    &nbsp;
                    <div className='divider'></div>
                    &nbsp;
                    &nbsp;
                    <div className='vendorNumber'>
                        <p>#{vendorNumber}</p>
                    </div>
            </div>
            <div className='linkBanner'>
                <div className='links'>
                    <a href="/#partner-dashboard"><p>Partner Dashboard</p></a>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <a href="/#stc-portal"><p>STC Portal</p></a>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <a href="/#payPortal"><p>Customer Pay Portal</p></a>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <a href="/#logout"><p>Logout</p></a> 
                </div>
            </div>
            <div className='topBanner'>
                <NavLink to="" className="dot-logo">
                    <DotLogo />
                </NavLink>
                <div className="pageName">
                    {pageName}
                </div>
            </div>
            <NavBar></NavBar>
        </header>
    );
}
