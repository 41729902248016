import React from 'react';

interface DashboardIconProps extends React.SVGProps<SVGSVGElement> {
    className?: string;
}

const DashboardIcon: React.FC<DashboardIconProps> = ({ className, ...props }) => {
    return (
        <svg
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            {...props}
        >
            <path d="M33.9403 14.2516L31.8903 17.335C32.879 19.3067 33.363 21.493 33.299 23.6978C33.235 25.9027 32.625 28.0572 31.5237 29.9683H8.42364C6.99226 27.4852 6.39914 24.6068 6.73221 21.76C7.06529 18.9133 8.30682 16.2496 10.2728 14.164C12.2387 12.0783 14.8244 10.6817 17.6465 10.1811C20.4687 9.68052 23.377 10.1026 25.9403 11.3849L29.0237 9.33493C25.8845 7.32195 22.1608 6.41993 18.4485 6.77315C14.7361 7.12637 11.2494 8.71442 8.54612 11.2833C5.84287 13.8521 4.07924 17.2533 3.53734 20.943C2.99542 24.6325 3.70656 28.3972 5.55697 31.635C5.84782 32.1387 6.26546 32.5577 6.76836 32.85C7.27126 33.1423 7.84192 33.298 8.42364 33.3017H31.507C32.0943 33.304 32.6718 33.151 33.1812 32.8583C33.6903 32.5657 34.1133 32.1437 34.407 31.635C35.9427 28.9748 36.7132 25.942 36.6338 22.8715C36.5545 19.801 35.628 16.8122 33.957 14.2349L33.9403 14.2516Z" className={className} />
            <path d="M17.6237 25.6517C17.9332 25.9615 18.3008 26.2073 18.7055 26.3752C19.1102 26.5428 19.544 26.6292 19.982 26.6292C20.42 26.6292 20.8538 26.5428 21.2585 26.3752C21.6632 26.2073 22.0307 25.9615 22.3403 25.6517L31.7737 11.5016L17.6237 20.935C17.3137 21.2445 17.0678 21.6122 16.9002 22.0168C16.7323 22.4215 16.646 22.8552 16.646 23.2933C16.646 23.7313 16.7323 24.165 16.9002 24.5697C17.0678 24.9743 17.3137 25.342 17.6237 25.6517Z" className={className} />
        </svg>
    );
};

export default DashboardIcon;
