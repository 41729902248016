import './NavBar.scss';
import DashboardIcon from 'components/SVG_Icons/DashboardIcon';
import PricingPortalIcon from 'components/SVG_Icons/PricingPortalIcon';
import InvoicesIcon from 'components/SVG_Icons/InvoicesIcon';
import SupplierLettersIcon from 'components/SVG_Icons/SupplierLettersIcon';
import SettingsIcon from 'components/SVG_Icons/SettingsIcon';
import { NavLink } from "react-router-dom";


type Props = {

}

export default function NavBar({ }: Props) {
    return (
        <div className="navBarBanner">
            <nav>
                <ul className="navLinks">
                    <li>
                        <NavLink to="" className="navItem">
                            <DashboardIcon className="navBarIcon" />
                            <span>DASHBOARD</span>
                        </NavLink>
                    </li>
                    <div className="navBarDivider"></div>
                    <li>
                        <NavLink to="history" className="navItem">
                            <InvoicesIcon className="navBarIcon" />
                            <span>TEMPLATE HISTORY</span>
                        </NavLink>
                    </li>
                    <div className="navBarDivider"></div>
                    <li>
                        <NavLink to="#supplierLetters" className="navItem">
                            <SupplierLettersIcon className="navBarIcon" />
                            <span>SUPPLIER LETTERS</span>
                        </NavLink>
                    </li>
                    <div className="navBarDivider"></div>
                    <li>
                        <NavLink to="settings" className="navItem">
                            <SettingsIcon className="navBarIcon" />
                            <span>SETTINGS</span>
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
