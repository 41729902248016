import React from 'react';

interface SupplierLettersIconProps extends React.SVGProps<SVGSVGElement> {
    className?: string;
}

const SupplierLettersIcon: React.FC<SupplierLettersIconProps> = ({ className, ...props }) => {
    return (
        <svg
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            {...props}
        >
            <path d="M31.6667 5H8.33333C6.5 5 5 6.5 5 8.33333V31.6667C5 33.5 6.48333 35 8.33333 35H31.6667C33.5 35 35 33.5 35 31.6667V8.33333C35 6.5 33.5 5 31.6667 5ZM31.6667 31.6667H8.33333V26.6667H14.2667C15.4167 28.65 17.55 30 20.0167 30C22.4833 30 24.6 28.65 25.7667 26.6667H31.6667V31.6667ZM31.6667 23.3333H23.35C23.35 25.1667 21.85 26.6667 20.0167 26.6667C18.1833 26.6667 16.6833 25.1667 16.6833 23.3333H8.33333V8.33333H31.6667V23.3333Z" className={className} />
        </svg>
    );
};

export default SupplierLettersIcon;
