import React from 'react';

interface InvoicesIconProps extends React.SVGProps<SVGSVGElement> {
    className?: string;
}

const InvoicesIcon: React.FC<InvoicesIconProps> = ({ className, ...props }) => {
    return (
        <svg
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            {...props}
        >
            <path d="M32.5 5.83333L30 3.33333L27.5 5.83333L25 3.33333L22.5 5.83333L20 3.33333L17.5 5.83333L15 3.33333L12.5 5.83333L10 3.33333V26.6667H5V31.6667C5 34.4333 7.23333 36.6667 10 36.6667H30C32.7667 36.6667 35 34.4333 35 31.6667V3.33333L32.5 5.83333ZM25 33.3333H10C9.08333 33.3333 8.33333 32.5833 8.33333 31.6667V30H25V33.3333ZM31.6667 31.6667C31.6667 32.5833 30.9167 33.3333 30 33.3333C29.0833 33.3333 28.3333 32.5833 28.3333 31.6667V26.6667H13.3333V8.33333H31.6667V31.6667Z" className={className} />
            <path d="M25 11.6667H15V15H25V11.6667Z" className={className} />
            <path d="M30 11.6667H26.6667V15H30V11.6667Z" className={className} />
            <path d="M25 16.6667H15V20H25V16.6667Z" className={className} />
            <path d="M30 16.6667H26.6667V20H30V16.6667Z" className={className} />
        </svg>
    );
};

export default InvoicesIcon;
