import { faCheckCircle, faCircleMinus, faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import OverviewCard from 'components/OverviewCards/OverviewCard';
import ViewAllButton from 'components/Buttons/ViewAllButton';
import HistoryRow from 'components/HistoryRows/HistoryRow';
import { ColorTypes } from 'enums';
import { ColorSettings } from 'types';
import './OverviewSection.scss';

export default function OverviewSection() {

    // Date format 
    let date = new Date();
    
    // Date converted to MM-DD-YYYY format 
    let dateMDY = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

    const approvedColors = { colorType: ColorTypes.Dot, key: "dark-green" } as ColorSettings,
          reviewColors = { colorType: ColorTypes.Dot, key: "yellow" } as ColorSettings,
          rejectedColors = { colorType: ColorTypes.Custom, key: "high-vis-orange" } as ColorSettings;
    
    return(
        <>
            <div className="overviewSection"> 
                <div className='leftSection'>
                    <div className='overviewTopArea'>
                        <h2>
                            <img className="overview-logo"
                                style={{ marginRight: '8px' }}
                                src={`${process.env.PUBLIC_URL}/eye.svg`}
                                alt="Dot Foods"
                            >
                            </img>Alerts
                        </h2> 
                    </div>
                    <div className='overviewBottomArea'>
                            <OverviewCard colorSettings={approvedColors} cardIcon={faCheckCircle} cardCategory='APPROVED' notificationCount={0}/>
                            <OverviewCard colorSettings={reviewColors} cardIcon={faCircleMinus} cardCategory='UNDER REVIEW' notificationCount={0}/>
                            <OverviewCard colorSettings={rejectedColors} cardIcon={faCircleExclamation} cardCategory='REJECTED' notificationCount={0}/>
                    </div>
                </div>
                <div className='rightSection'>
                    <div className='historyContext'>
                        <div className='historyTitle'>
                            <h2 className='overviewTopArea'>
                                <img className="history-logo"
                                    style={{ marginRight: '8px' }}
                                    src={`${process.env.PUBLIC_URL}/layers.svg`}
                                    alt="Dot Foods"
                                >
                                </img>History
                            </h2>
                            <ViewAllButton/>
                        </div>
                        <hr className='historyDivider'/>

                            {[...Array(5)].map((_, index) => (
                                <HistoryRow key={index} info={"Template Information"} index={index} status={'Accepted'} date={dateMDY} priceListNumber={"AA007"}/>
                            ))}

                    </div>
                </div>
            </div>   
        </>
    );
}